<template>
  <div>
    <!-- <app-layout></app-layout> -->
    <!-- <component :is="customerLayout" /> -->
    <RouterView />
  </div>
</template>

<script lang="ts" setup>
// import appLayout from "./layouts/default.vue";
import { ref, onMounted } from "vue";
// import { useLocationStore } from "@/stores/locationStore";
import { useRoute } from "vue-router";

const customer = import.meta.env.VITE_CUSTOMER;
const customerLayout = ref(null);

// const locationStore = useLocationStore();
const route = useRoute();

onMounted(async () => {
  const locationSlug = route.params.locationSlug;
  if (locationSlug) {
    // await locationStore.fetchLocationDetail(locationSlug);
  } else {
    // locationStore.fetchLocation();
  }
});
</script>
