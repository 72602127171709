import { defineStore } from 'pinia';
import apiService from '@/api/apiService';

interface Site extends Record<string, any> {
  id: string;
  name: string;
  slug: string;
  brand_color: string;
  text_color: string;
}

interface SiteState {
  sites: any[];
  selectedSite: any | null;
  locations: any | null;
  homepageCta: any | null;
  todaysMenu: any | null;
  stats: any | null;
  featuredContent: object | null;
  loading: boolean;
  error: Error | null;
  lastFetchedSitePath: string | null;
}

export const useSiteStore = defineStore('site', {
  state: (): SiteState => ({
    sites: [],
    selectedSite: null,
    locations: [],
    homepageCta: {}, 
    todaysMenu: [], 
    stats: {}, 
    featuredContent: {}, 
    loading: false,
    error: null,
    lastFetchedSitePath: null,
  }),
  actions: {
    async fetchSites() {
      this.loading = true;
      this.error = null;
      try {
        const response = await apiService.get('/sites/public');
        this.sites = response.data;
      } catch (error) {
        this.handleApiError(error);
      } finally {
        this.loading = false;
      }
    },
    
    async fetchSiteDetail(slug) {
      // Check slug to see if it is already fetched
      if (this.lastFetchedSitePath === slug && this.selectedSite) {
        return true;
      }

      this.loading = true;
      this.error = null;
      const params = { slug: slug };

      try {
        const response = await apiService.get('/sites/public_detail', params);
        if (response.data && response.data.site) {
          this.setSite(response.data.site);
          this.lastFetchedSitePath = slug;
          return true;
        } else {
          return false;
        }
      } catch (error) {
        this.handleApiError(error);
        return false;
      } finally {
        this.loading = false;
      }
    },

    async fetchSiteMapLocations(siteId) {
      this.loading = true;
      try {
        this.locations = []; 
        const response = await apiService.get(`/sites/${siteId}/locations-public?for_map=true`);
        this.locations = response.data;
      } catch (error) {
        this.handleApiError(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchSiteHomepageCta(siteId) {
      this.loading = true;
      try {
        this.homepageCta = []; 
        const response = await apiService.get(`/sites/homepage-cta?siteId=${siteId}`);
        this.homepageCta = response.data.homepageCta;
      } catch (error) {
        this.handleApiError(error);
      } finally {
        this.loading = false;
      }
    },
    
    async fetchSiteTodaysMenu(siteId) {
      this.loading = true;
      try {
        this.todaysMenu = []; 
        const response = await apiService.get(`/sites/todays_menu?siteId=${siteId}`);
        this.todaysMenu = response.data;
      } catch (error) {
        this.handleApiError(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchSiteStats(siteId) {
      this.loading = true;
      try {
        this.stats = []; 
        const response = await apiService.get(`/sites/stats?siteId=${siteId}`);
        this.stats = response.data.stats;
      } catch (error) {
        this.handleApiError(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchFeaturedContent(siteId) {
      this.loading = true;
      try {
        this.featuredContent = []; 
        const response = await apiService.get(`/sites/${siteId}/featured-content`);
        this.featuredContent = response.data;
      } catch (error) {
        this.handleApiError(error);
      } finally {
        this.loading = false;
      }
    },

    setSite(site: Record<string, any>) {
      console.log("setting site..", site);
      this.selectedSite = site;
      console.log("set site..", this.selectedSite);
      localStorage.setItem('selectedSite', JSON.stringify(site));
    },

    fetchSite() {
      const storedSite = localStorage.getItem('selectedSite');
      if (storedSite) {
        const site: Site = JSON.parse(storedSite);
        this.setSite(site);
      }
    },

    handleApiError(error: any) {
      this.error = error instanceof Error ? error : new Error('An unknown error occurred');
      console.error('API Error:', this.error);
    },
  },
});
