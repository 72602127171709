import { createRouter, createWebHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import { useAppStore } from '@/stores/index';
import { nextTick } from 'vue';
import { useSiteStore } from '@/stores/siteHermosa';

const customerCode = import.meta.env.VITE_CUSTOMER;
const platformName = import.meta.env.VITE_PLATFORM_NAME;

const Placeholder = {
  template: '<div>Placeholder</div>',
};

const checkSiteExists = async (to, from, next) => {
  const siteStore = useSiteStore();
  const siteExists = await siteStore.fetchSiteDetail(to.params.sitePath);
  if (siteExists) {
    console.log(`Navigating to ${to.name} page under:`, to.params.sitePath);
    next();
  } else {
    console.log(`In route-- site does not exist. 404.`);
    next({ name: 'NotFound' });
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'DiscoverLayout',
    component: () => import(`@/layouts/customers/${customerCode}/Discover.vue`),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(`@/views/customers/${customerCode}/DiscoveryPage.vue`),
        props: route => ({ customerCode: customerCode })
      },
    ]
  },
  {
    path: '/',
    name: 'SiteLayout',
    component: () => import(`@/layouts/customers/${customerCode}/Site.vue`),
    children: [
      {
        path: '/:sitePath',
        name: 'SitePage',
        component: () => import(`@/views/customers/${customerCode}/SitePage.vue`),
        props: route => ({ customerCode, sitePath: route.params.sitePath }),
        beforeEnter: checkSiteExists,
      },

      // subpages
      {
        path: '/:sitePath/campus-map',
        name: 'CampusMap',
        component: () => import(`@/views/customers/${customerCode}/CampusMap.vue`),
        props: route => ({ customerCode, sitePath: route.params.sitePath }),
        beforeEnter: checkSiteExists,
      },
      {
        path: '/:sitePath/events',
        name: 'Events',
        component: () => import(`@/views/customers/${customerCode}/Events.vue`),
        props: route => ({ customerCode, sitePath: route.params.sitePath }),
        beforeEnter: checkSiteExists,
      },
      {
        path: '/:sitePath/special-offers',
        name: 'SpecialOffers',
        component: () => import(`@/views/customers/${customerCode}/SpecialOffers.vue`),
        props: route => ({ customerCode, sitePath: route.params.sitePath }),
        beforeEnter: checkSiteExists,
      },
      {
        path: '/:sitePath/news',
        name: 'News',
        component: () => import(`@/views/customers/${customerCode}/News.vue`),
        props: route => ({ customerCode, sitePath: route.params.sitePath }),
        beforeEnter: checkSiteExists,
      },
      {
        path: '/:sitePath/feedback',
        name: 'Feedback',
        component: () => import(`@/views/customers/${customerCode}/Feedback.vue`),
        props: route => ({ customerCode, sitePath: route.params.sitePath }),
        beforeEnter: checkSiteExists,
      },
      {
        path: '/:sitePath/hours-of-operation',
        name: 'HoursOfOperation',
        component: () => import(`@/views/customers/${customerCode}/HoursOfOperation.vue`),
        props: route => ({ customerCode, sitePath: route.params.sitePath }),
        beforeEnter: checkSiteExists,
      },
      {
        path: '/:sitePath/login',
        name: 'Login',
        component: () => import(`@/views/customers/${customerCode}/Login.vue`),
        props: route => ({ customerCode, sitePath: route.params.sitePath }),
        beforeEnter: checkSiteExists,
      },
      {
        path: '/:sitePath/meal-plan-purchase',
        name: 'MealPlanPurchase',
        component: () => import(`@/views/customers/${customerCode}/MealPlanPurchase.vue`),
        props: route => ({ customerCode, sitePath: route.params.sitePath }),
        beforeEnter: checkSiteExists,
      },
      {
        path: '/:sitePath/staff',
        name: 'Staff',
        component: () => import(`@/views/customers/${customerCode}/Staff.vue`),
        props: route => ({ customerCode, sitePath: route.params.sitePath }),
        beforeEnter: checkSiteExists,
      },
      {
        path: '/:sitePath/whats-on-the-menu',
        name: 'WhatsOnTheMenu',
        component: () => import(`@/views/customers/${customerCode}/WhatsOnTheMenu.vue`),
        props: route => ({ customerCode, sitePath: route.params.sitePath }),
        beforeEnter: checkSiteExists,
      },
			{
        path: '/:sitePath/signup',
        name: 'Signup',
        component: () => import(`@/views/customers/${customerCode}/Signup.vue`),
        props: route => ({ customerCode, sitePath: route.params.sitePath }),
        beforeEnter: checkSiteExists,
      },
    ],
  },
  {
    path: '/:slug/login',
    name: 'login',
    component: Placeholder,
  },
  {
    path: '/:slug/signup',
    name: 'signup',
    component: Placeholder,
  },
  {
    path: '/:pathMatch(.*)*', // wildcard route to catch all paths
    name: 'NotFound',  // 404
    component: () => import('@/layouts/error.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach((to, from, next) => {
    const store = useAppStore();
    nextTick(() => {
        document.title = (to.meta.title || platformName) as never;
    });
    store.toggleMainLoader(false);
});

export default router;
