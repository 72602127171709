// src/api/apiService.js
import apiClient from '../plugins/axios';

export default {
  get(resource, params) {
    return apiClient.get(resource, { params });
  },
  post(resource, data) {
    return apiClient.post(resource, data);
  },
  put(resource, data) {
    return apiClient.put(resource, data);
  },
  delete(resource) {
    return apiClient.delete(resource);
  },
};
