import { createApp } from 'vue';
import App from './App.vue';

// Create the app instance
const app = createApp(App);

// Pinia
import { createPinia } from 'pinia';
const pinia = createPinia();
app.use(pinia);

import router from './router';
app.use(router);

// Main app CSS
import './assets/css/app.css';

// Set default settings
import appSetting from '@/app-setting';
appSetting.init();

// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
    once: true,
});

// Font Awesome
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
} from '@fortawesome/vue-fontawesome'
import '@/lib/font-awesome-icons'
app.component('FontAwesomeIcon', FontAwesomeIcon) // Use the icon component anywhere in the app
app.component('font-awesome-layers', FontAwesomeLayers)

// Mount the app
app.mount('#app');
