import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRight as faArrowRightSolid,
  faArrowUpRightFromSquare,
  faCommentPen,
  faDownload,
  faEnvelope as faEnvelopeSolid,
  faHandHoldingSeedling,
  faMoneyCheckDollar,
  faPhone as faPhoneSolid,
  faPlateUtensils,
  faPrint as faPrintSolid,
  faReply,
  faShare,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faArrowLeft,
  faArrowRight,
  faBars,
  faCalendar,
  faCheckCircle,
  faChevronsRight,
  faCircle,
  faClock,
  faComment,
  faHatChef,
  faFilePdf as faFilePdfRegular,
  faLocationArrow,
  faMap,
  faMoneyBillAlt,
  faNewspaper,
  faQuestionCircle,
  faSignal,
  faTimes,
  faXmark,
  faUtensilsAlt as faUtensilsReg,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBan,
  faBrightness,
  faBuilding,
  faCalendarAlt,
  faCalendarDay,
  faCalendarCheck,
  faCalendarEdit,
  faCalendarPlus,
  faCancel,
  faCashRegister,
  faChevronDown,
  faChevronCircleRight,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faCreditCard,
  faClock as faClockLight,
  faClipboardList,
  faCloudDownload,
  faCoffeeTogo,
  faCow,
  faEgg,
  faEnvelope,
  faExternalLink,
  faExternalLinkSquare,
  faFileCheck,
  faFilePdf,
  faFire,
  faFish,
  faHeartbeat,
  faHeartPulse,
  faIdBadge,
  faIdCard,
  faInfoCircle,
  faLeaf,
  faLeafyGreen,
  faLock,
  faMap as faMapLight,
  faMapMarker,
  faMapMarkerAlt,
  faMoon,
  faPause,
  faPauseCircle,
  faPeanut,
  faPencil,
  faPhoneAlt,
  faPhoneRotary,
  faPlay,
  faPlayCircle,
  faPrint,
  faSearch,
  faShieldCheck,
  faShoppingCart,
  faSignIn,
  faSnowflake,
  faSpinnerThird as faSpinnerThirdLight,
  faSun,
  faTag,
  faTimes as faTimesLight,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faUserCheck,
  faUserEdit,
  faUtensilsAlt as faUtensils,
  faXmark as faXmarkLight,
} from '@fortawesome/pro-light-svg-icons'
import {
  faApplePay,
  faCcAmex,
  faFacebook,
  faFlickr,
  faGooglePay,
  faInstagram,
  faCcMastercard,
  faLinkedin,
  faPinterest,
  faSnapchat,
  faTiktok,
  faTwitter,
  faVimeo,
  faVimeoV,
  faCcVisa,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faApplePay,
  faArrowLeft,
  faArrowRight,
  faArrowRightSolid,
  faArrowUpRightFromSquare,
  faBan,
  faBars,
  faBrightness,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarEdit,
  faCalendarPlus,
  faCancel,
  faCashRegister,
  faCcAmex,
  faCheckCircle,
  faChevronDown,
  faChevronCircleRight,
  faChevronRight,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faChevronsRight,
  faCircle,
  faClipboardList,
  faClock,
  faCloudDownload,
  faClockLight,
  faCoffeeTogo,
  faComment,
  faCommentPen,
  faCow,
  faCreditCard,
  faDownload,
  faEgg,
  faEnvelope,
  faEnvelopeSolid,
  faExternalLink,
  faExternalLinkSquare,
  faFacebook,
  faFileCheck,
  faFilePdf,
  faFilePdfRegular,
  faFire,
  faFish,
  faFlickr,
  faGooglePay,
  faHandHoldingSeedling,
  faHatChef,
  faHeartbeat,
  faHeartPulse,
  faIdBadge,
  faIdCard,
  faInfoCircle,
  faInstagram,
  faLeaf,
  faLeafyGreen,
  faLinkedin,
  faLocationArrow,
  faLock,
  faMap,
  faMapLight,
  faMapMarker,
  faMapMarkerAlt,
  faMoon,
  faMoneyCheckDollar,
  faCcMastercard,
  faMoneyBillAlt,
  faNewspaper,
  faPause,
  faPauseCircle,
  faPeanut,
  faPencil,
  faPhoneAlt,
  faPhoneRotary,
  faPhoneSolid,
  faPinterest,
  faPlateUtensils,
  faPlay,
  faPlayCircle,
  faPrint,
  faPrintSolid,
  faQuestionCircle,
  faReply,
  faSearch,
  faShare,
  faShieldCheck,
  faShoppingCart,
  faSignal,
  faSignIn,
  faSnowflake,
  faSnapchat,
  faSpinnerThird,
  faSpinnerThirdLight,
  faSun,
  faTag,
  faTiktok,
  faTimes,
  faTimesCircle,
  faTimesLight,
  faTrashAlt,
  faTwitter,
  faUser,
  faUserCheck,
  faUserEdit,
  faUtensils,
  faUtensilsReg,
  faVimeo,
  faVimeoV,
  faCcVisa,
  faXmark,
  faXmarkLight,
  faXTwitter,
  faYoutube,
) // Use any icon from the Solid style
